/** @jsx jsx */
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Helmet from "react-helmet";
import { Box, Container, Flex, Button, jsx } from "theme-ui";
import AccidentTypeQuickSelection from "../components/AccidentTypeQuickSelection";
import Hero from "../components/Hero";
import Layout from "../components/Layout";
import LiftUpLinks from "../components/LiftUpLinks";
import RichText2 from "../components/RichText2";
import SEO from "../components/SEO";
import Share from "../components/Share";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import { useSitePages } from "../hooks/useSitePages";
import { createImageUrl, getUrlFromSlug } from "../utils/utils";
import ContentTopicLinks from "../components/ContentTopicLinks";
import RelatedContent from "../components/RelatedContent";

const ThemePageTemplate = ({ data, pageContext }) => {
  const nodes = useSitePages();
  if (!data.contentfulThemePage) return null;
  const {
    pageType,
    title,
    hero,
    navigationTitle,
    slug,
    metaKeywords,
    metaDescription,
    metaTitle,
    contentArea,
    bottomContentArea,
    liftupLinks,
    component,
    bottomImage,
    hidden,
    noIndex,
    sharePage,
    enableAbTesting,
    showNpsPopup,
    canonicalTag,
    contentTopics,
  } = data.contentfulThemePage;

  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    defaultImage,
    author,
    social,
    organization,
  } = useSiteMetadata();

  const { node_locale: locale, language } = pageContext;

  let metaImage =
    hero && hero.image && hero.image.gatsbyImageData
      ? createImageUrl(hero.image.gatsbyImageData)
      : siteUrl + defaultImage;

  let metadataTitle = metaTitle || title;

  const metadataDescription =
    metaDescription &&
    metaDescription.internal &&
    metaDescription.internal.content
      ? metaDescription.internal.content
      : defaultDescription;

  return (
    <Layout
      pageContext={pageContext}
      pageType={pageType}
      enableAbTesting={enableAbTesting}
      showNpsPopup={showNpsPopup}
    >
      <SEO
        lang={pageContext.language}
        title={title}
        description={metadataDescription}
        image={metaImage}
        paths={pageContext.paths}
        hidden={hidden}
        noIndex={noIndex}
        metadataTitle={metadataTitle}
        canonicalTag={canonicalTag}
      />
      <Container id="content">
        {hero && (
          <Box sx={{ mx: [-3, -4, 0], mb: [4, null, 6] }}>
            <Hero {...hero} />
          </Box>
        )}
        {/**<BreadCrumbs slug={slug} navigationTitle={navigationTitle} />*/}
        {contentArea && contentArea.raw && (
          <RichText2
            content={contentArea}
            locale={locale}
            language={language}
          />
        )}
      </Container>

      <Container sx={{ mb: [4, null, 6] }}>
        {liftupLinks && <LiftUpLinks {...liftupLinks} />}

        {component && component.data && (
          <Flex
            sx={{
              alignItems: "stretch",
              py: [4, null, 6],
              overflow: "hidden",
            }}
          >
            <Box sx={{ flex: "1 1 auto", width: ["auto", null, "70%"] }}>
              <AccidentTypeQuickSelection
                title={component.title}
                data={component.data}
              />
            </Box>
            <Box
              sx={{
                display: ["none", null, "block"],
                flex: "none",
                width: "30%",
                ml: 3,
              }}
            >
              {bottomImage && (
                <GatsbyImage
                  image={bottomImage.gatsbyImageData}
                  sx={{
                    width: "100%",
                    height: "100%",
                    minHeight: [200, null, "auto"],
                    borderTopRightRadius: 100,
                  }}
                />
              )}
            </Box>
          </Flex>
        )}
        {bottomContentArea && bottomContentArea.raw && (
          <RichText2
            content={bottomContentArea}
            locale={locale}
            language={language}
            sx={{ px: 0 }}
          />
        )}
        {contentTopics && (
          <Container sx={{ px: [0, 0, 0, 0] }}>
            <RelatedContent
              contentTopics={contentTopics}
              locale={locale}
              currentPageSlug={slug}
              pageType={pageType}
            />
            <ContentTopicLinks contentTopics={contentTopics} />
          </Container>
        )}
        {sharePage ? (
          <Container
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Share language={language} />
          </Container>
        ) : (
          ""
        )}
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!) {
    contentfulThemePage(id: { eq: $id }) {
      id
      contentful_id
      __typename
      pageType
      title
      slug
      navigationTitle
      sharePage
      canonicalTag
      metaDescription {
        internal {
          content
        }
      }
      metaTitle
      navigationIcon {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      hero {
        id
        contentful_id
        __typename
        title
        type
        description
        node_locale
        image {
          gatsbyImageData(quality: 85, width: 1080)
        }
        linkText
        link {
          ... on ContentfulFrontpage {
            slug
          }
          ... on ContentfulPage {
            slug
          }
          ... on ContentfulProductPage {
            slug
          }
        }
        analyticsAction
        analyticsId
        target
      }

      contentArea {
        raw
        references {
          ... on Node {
            ...fragmentContentfulFrontpage
          }
          ... on Node {
            ...fragmentContentfulThemePage
          }
          ... on Node {
            ...fragmentContentfulProductPage
          }
          ... on Node {
            ...fragmentContentfulPage
          }
          ... on Node {
            ...fragmentContentfulContentHubThemePage
          }
          ... on Node {
            ...fragmentContentfulContentHubPage
          }
          ... on Node {
            ...fragmentContentfulContentHubContentTypePage
          }
          ... on Node {
            ...fragmentContentfulContentHubTopicPage
          }
          ... on Node {
            ...fragmentContentfulContentfulInsuranceTermsPage
          }
          ... on Node {
            ...fragmentContentfulInlineImage
          }
          ...fragmentContentfulCallToAction
          ...fragmentContentfulAccordion
          ...fragmentContentfulLiftUpBox
          ...fragmentContentfulEmbeddedNotification
          ...fragmentContentfulContentHubLiftUps
          ...fragmentContentfulLiftUpLinks
          ...fragmentContentfulResponsiveIframe
          ... on Node {
            ...fragmentContentfulDynamicSection
          }
          ... on Node {
            ...fragmentContentfulProcessGuide
          }
        }
      }

      bottomContentArea {
        raw
        references {
          ...fragmentContentfulCallToAction
          ...fragmentContentfulAccordion
          ...fragmentContentfulLiftUpBox
          ...fragmentContentfulEmbeddedNotification
          ...fragmentContentfulContentHubLiftUps
          ...fragmentContentfulLiftUpLinks
          ... on Node {
            ...fragmentContentfulDynamicSection
          }
          ... on Node {
            ...fragmentContentfulProcessGuide
          }
          ... on Node {
            ...fragmentContentfulInlineImage
          }
        }
      }

      bottomImage {
        gatsbyImageData(quality: 85, width: 1080)
      }
      component {
        data {
          title
          quickSelections {
            slug
            title
            url
          }
          allSelections {
            slug
            title
            url
          }
        }
      }

      liftupLinks {
        title
        columns
        hideTitle
        links {
          ... on ContentfulPage {
            id
            contentful_id
            __typename
            navigationTitle
            navigationIcon {
              fluid {
                ...GatsbyContentfulFluid
              }
            }
            slug
          }
          ... on ContentfulProductPage {
            id
            contentful_id
            __typename
            navigationTitle
            navigationIcon {
              fluid {
                ...GatsbyContentfulFluid
              }
            }
            slug
          }
          ... on ContentfulThemePage {
            id
            contentful_id
            __typename
            navigationTitle
            navigationIcon {
              fluid {
                ...GatsbyContentfulFluid
              }
            }
            slug
          }
          ... on ContentfulLiftUpLinkAsset {
            id
            contentful_id
            __typename
            navigationTitle
            url
            mediaAssetfile {
              file {
                url
              }
            }
            id
            navigationIcon {
              fluid {
                ...GatsbyContentfulFluid
              }
              title
            }
          }
        }
      }
      hidden
      noIndex
      enableAbTesting
      showNpsPopup
      contentTopics {
        ...fragmentContentfulContentTopic
      }
    }
  }
`;

export default ThemePageTemplate;
